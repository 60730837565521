

import * as Fetch from "bs-fetch/src/Fetch.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function getBlob(url) {
  var request = Fetch.RequestInit.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* NoStore */1, undefined, undefined, undefined, undefined)(undefined);
  var __x = fetch(url, request);
  var __x$1 = __x.then((function (prim) {
          return prim.blob();
        }));
  var __x$2 = __x$1.then((function (blob) {
          return Promise.resolve(Caml_option.some(blob));
        }));
  return __x$2.catch((function (err) {
                console.log("fetch error:", err);
                return Promise.resolve(undefined);
              }));
}

export {
  getBlob ,
  
}
/* No side effect */
